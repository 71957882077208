<footer class="bg-dark text-white">
  <div class="container">
    <footer class="pt-5 pb-3">
      <div class="row">
        <div class="col-12 col-md-3 mb-3">
          <div class="d-flex align-items-end gap-2 mb-3">
            <img src="assets/img/logo.png" height="80px" width="100px" alt="">
          </div>
        </div>
        <div class="col-12 col-md-3 mb-3">
          <h5>Quick Links</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-light">Home | About us | Services</a></li>
         
          </ul>
        </div>
        <div class="col-12 col-md-3 mb-3">
          <h5>Contact info</h5>
          <ul class="nav flex-column">
            
            <li class="nav-item mb-2">
              <a href="#" class="nav-link p-0 text-light">
                 <i class="fas fa-envelope fa-fw me-2"></i>teamswatlogistics@gmail.com

              </a>
            </li>
          
          </ul>
        </div>
        <div class="col-12 col-md-3 mb-3">
          <form>
            <h5>Subscribe to our newsletter</h5>
            <div class="d-flex flex-column flex-sm-row w-100 gap-2">
              <label for="newsletter1" class="visually-hidden">Email address</label>
              <input id="newsletter1" type="text" class="form-control" placeholder="Email address">
              <button class="btn btn-primary" type="button">Subscribe</button>
            </div>
          </form>
        </div>
      </div>

      <div class="d-flex flex-column flex-sm-row justify-content-between pt-4 border-top">
        <p>© 2022 Company, Inc. All rights reserved.</p>
        <ul class="list-unstyled d-flex">
          <li class="ms-3">
            <a class="link-light" href="#">
              <i class="fab fa-facebook-f fa-fw"></i>
            </a>
          </li>
          <li class="ms-3">
            <a class="link-light" href="#">
              <i class="fab fa-twitter fa-fw"></i>
            </a>
          </li>
          <li class="ms-3">
            <a class="link-light" href="#">
              <i class="fab fa-linkedin-in fa-fw"></i>
            </a>
          </li>
          <li class="ms-3">
            <a class="link-light" href="#">
              <i class="fab fa-instagram fa-fw"></i>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</footer>