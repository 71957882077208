<app-header></app-header>
<div class="hero-section section pt-0">
    <div class="container pt-5">
 
    </div>
   
  </div>

  <div class="about-section section container">
    <div class="row">
      <div class="col-12 col-lg-12 mb-3 mb-lg-0">
        <p class="display-5 fw-normal">Team Swat</p>
        <p style="text-align: justify;">
          Team SWAT logistics and Management LTD is the new buzz in the country for your travel needs. We pride ourselves in comfortable and affordable experience. Our customer service is quick and personalized to consistently meet & surpass our client’s expectations.

          Our destinations are fairly distributed across Nyanza, South Rift Valley & western Kenya, include Kapsabet, Chavakali, Kakamega, Malava, Luanda, Kisumu and Kericho; all circling back to Nairobi City. However, with our exponentially growing client base, we are confident to expand to other regions soon.
          
          In addition to shuttle services, we also offer the most efficient parcel services, car hire & rentals at competitive prices. Customized fleet management tops our portfolio.
        </p>
       
      </div>
      
    </div>
  </div>
  <app-footer></app-footer>
  